import React from 'react';
import loadable from '@loadable/component';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import RichText from '../../elements/richText';

const Slideshow = styled.div`
  position: relative;
  padding: 60px 60px 75px;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    padding: 60px 40px 75px;
  }

  @media(max-width: ${props => props.theme.breakpoints.max.sm}){
    padding: 60px 30px 75px;
  }  
`;

const CarouselContainer = styled.div`
  max-width: 820px;
  margin: auto;
`;

const StyledHeading = styled.h2`
  font-style: normal;
  margin-bottom: 0px;
`;

const StyledSubHeading = styled.div`
  p{
    margin-top: 0px;
  }
`;

const SlideImage = styled.div`
  width: 100%;
  height: 400px;
  background-size: cover;
  background-position: center;

  @media(max-width: ${props => props.theme.breakpoints.max.lg}){
    height: 45vw;
  }
`;

const Carousel = loadable(() => import('../../elements/slickSlider'));
export default function SlideshowComponent({
  data
}) {
  const {
    slideshowHeading: heading,
    slideshowSubheading: subheading,
    images,
    componentBackground,
    columnsTablet,
    columnsDesktop
  } = data;

  const carouselSettings = {
    autoplaySpeed: 3000,
    autoplay: true,
    infinite: true,
    slidesToShow: columnsDesktop || 1,
    arrows: false,
    dots: true,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: columnsTablet || 1,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          dots: true
        }
      }
    ]
  };

  return (
    <Slideshow className={`component-background_${componentBackground}`}>
      <CarouselContainer>
        <StyledHeading>{heading}</StyledHeading>
        {subheading && (
          <StyledSubHeading><RichText text={subheading} /></StyledSubHeading>
        )}
        <Carousel settings={carouselSettings}>
          {images.map((image) => (
            <div key={image.file.url}>
              <SlideImage style={{ backgroundImage: `url(${image.file.url})` }} />
            </div>
          ))}
        </Carousel>
      </CarouselContainer>
    </Slideshow>
  );
}

SlideshowComponent.propTypes = {
  data: PropTypes.shape({
    slideshowHeading: PropTypes.string,
    slideshowSubheading: PropTypes.shape({
      childContentfulRichText: PropTypes.shape({
        html: PropTypes.string
      })
    }),
    images: PropTypes.arrayOf(PropTypes.shape({
      file: PropTypes.shape({
        url: PropTypes.string
      })
    })),
    componentBackground: PropTypes.string,
    columnsTablet: PropTypes.number,
    columnsDesktop: PropTypes.number
  })
};
